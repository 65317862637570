import React from "react";

import "./Section.scss";

export default function Section({ className, children }) {
	return (
		<section className={`section ${className}`}>
			<div className="section__container">{children}</div>
		</section>
	);
}
