import React from "react";
import { Helmet } from "react-helmet";
import { useLayoutMode } from "../containers/LayoutModeContext";
import InfoBar from "../components/InfoBar";
import LayoutGrid from "../components/LayoutGrid";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Layout({ children }) {
	const layoutMode = useLayoutMode();

	return (
		<main className="layout">
			<Helmet>
				<body className={layoutMode}></body>
			</Helmet>
			<InfoBar />
			<LayoutGrid />
			<Header />
			{children}
			<Footer />
		</main>
	);
}
