import React from "react";
import { useGridVisibilityState } from "../containers/GridVisibilityContext";

import "./LayoutGrid.scss";

export default function LayoutGrid() {
	const { visibility } = useGridVisibilityState();

	return (
		<div className={`layout-grid ${visibility ? "layout-grid--visible" : ""}`}>
			<div className="layout-grid__container">
				{new Array(48).fill(true).map((value, index) => {
					return <div key={index}></div>;
				})}
			</div>
		</div>
	);
}
