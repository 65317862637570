import React, { createContext, useContext } from "react";

const LayoutModeContext = createContext();

export function useLayoutMode() {
	return useContext(LayoutModeContext);
}

export function LayoutModeProvider({ mode, children }) {
	return (
		<LayoutModeContext.Provider value={mode}>
			{children}
		</LayoutModeContext.Provider>
	);
}
