import React from "react";
import { Link as GatsbyLink } from "gatsby";

export default function Link({ children, to, ...props }) {
	return (
		<GatsbyLink to={to} {...props}>
			{children}
		</GatsbyLink>
	);
}
