import React, { useState, useEffect, useCallback } from "react";
import { useLayoutMode } from "../containers/LayoutModeContext";
import { useGridVisibilityState } from "../containers/GridVisibilityContext";

import "./InfoBar.scss";

export default function InfoBarFixed() {
	const layoutMode = useLayoutMode();
	const [screenSize, setScreenSize] = useState();
	const { visibility, setVisibility } = useGridVisibilityState();

	const toggleClickListener = useCallback(() => {
		setVisibility(!visibility);
	}, [visibility]);

	useEffect(() => {
		const listener = () => {
			setScreenSize(window.innerWidth);
		};

		window.addEventListener("resize", listener);
		listener();

		return () => window.addEventListener("resize", listener);
	}, []);

	return (
		<div className="info-bar">
			<div className="info-bar__min">Min (&lt; 320px)</div>
			<div className="info-bar__mn">MN (320px - 479px)</div>
			<div className="info-bar__sm">SM (480px - 767px)</div>
			<div className="info-bar__md">MD (768px - 1279px)</div>
			<div className="info-bar__lg">LG (1280px - 1679px)</div>
			<div className="info-bar__ms">MS (1680px - 2199px)</div>
			<div className="info-bar__mx-percentage">MS (1680px - 2199px)</div>
			<div className="info-bar__mx">MX (&gt; 2200px)</div>
			<div className="info-bar__current-size">
				Current size: {screenSize && <span>{screenSize}px</span>}
			</div>

			<div className="info-bar__toggle" onClick={toggleClickListener}>
				<span>{visibility ? "Hide Layout Grid" : "Show Layout Grid"}</span>
			</div>
		</div>
	);
}
