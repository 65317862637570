import React from "react";

import "./Footer.scss";

export default function Footer() {
	return (
		<div className="footer">
			<div className="footer__container">
				<div className="footer__inner">
					<p className="meta">&copy; {new Date().getFullYear()} Gladeye</p>
				</div>
			</div>
		</div>
	);
}
