import React from "react";
import Link from "./Link";

import "./Header.scss";

export default function Header() {
	return (
		<div className="header">
			<div className="header__container">
				<div className="header__inner">
					<div className="header__logo">
						<a href="/">
							<svg
								width="177"
								height="100"
								viewBox="0 0 177 100"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<g transform="translate(-42, 0)">
									<circle cx="72" cy="50" r="30" fill="#999999" />
									<ellipse
										cx="104.647"
										cy="50"
										rx="29.7059"
										ry="30"
										fill="#bdbdbd"
									/>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M88.4039 75.1221C96.5911 69.7652 102 60.5143 102 50C102 39.4858 96.5911 30.2348 88.4039 24.878C80.2971 30.2348 74.9412 39.4858 74.9412 50C74.9412 60.5143 80.2971 69.7652 88.4039 75.1221Z"
										fill="#4a4a4a"
									/>
								</g>
							</svg>
						</a>
					</div>

					<nav className="header__nav">
						<div className="header__nav__burger-icon">
							<span></span>
							<span></span>
							<span></span>
						</div>

						<ul className="header__nav__items">
							<li>
								<p>
									<Link
										to="/"
										className="header__nav__link"
										activeClassName="header__nav__link--active">
										Standard
									</Link>
								</p>
							</li>
							<li>
								<p>
									<Link
										to="/fixed"
										className="header__nav__link"
										activeClassName="header__nav__link--active">
										Fixed
									</Link>
								</p>
							</li>
							<li>
								<p>
									<Link
										to="/percentage"
										className="header__nav__link"
										activeClassName="header__nav__link--active">
										Percentage
									</Link>
								</p>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</div>
	);
}
